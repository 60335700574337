@import url("https://fonts.googleapis.com/css2?family=Nanum+Myeongjo:wght@700&display=swap");
.navbarContent {
  /* navbar */
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
  padding-inline-start: 1rem;
  background-color: #1e1e1e;
}

#navMobile {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  background-color: #1e1e1e;
}

.signInLinks {
  text-align: center;
  border: none;
  outline: none;
  font-family: "Monserrat", sans-serif;
  padding: 5px 20px;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
  width: 180px;
  white-space: nowrap;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.4rem;
  color: #fff;
  background: #2ecc71;
}

.signInLinks:hover {
  color: black;
  transition: all 0.3s ease-out;
  transition: 250ms;
}

.nav-links {
  color: white;
  margin: 0rem 1rem;
  font-weight: bold;
  cursor: pointer;
}
.nav-links:hover {
  text-decoration: underline;
}

@media screen and (min-width: 1065px) {
  .sticky {
    position: sticky;
    position: -webkit-sticky;
    top: 0; /* required */
  }
}

#logo:active {
  text-decoration: none;
  border: none;
}

#logo:focus {
  border: none;
  text-decoration: none;
}

.just-gb-logo {
  width: 185px;
  height: 43px;
  cursor: pointer;
  transform: translate(0%, 4%);
  place-self: center;
  display: flex;
  justify-content: start;
}

.navbarHeight {
  height: 75px;
}

.dialogTitle {
  font-size: 2rem;
  font-weight: 240;
  color: #fff;
}

.logo {
  height: 56px;
  cursor: pointer;
  transition: 0.3s;
  transform: translate(-17px, -8px);
}
.logo:hover {
  transform: translate(-17px, -10px) scale(1);
}

:dir(rtl) .logo {
  transform: translate(17px, -8px); /* Reverse the x-axis translation */
}
:dir(rtl) .logo:hover {
  transform: translate(17px, -10px) scale(1);
}

.smallBackgroundLogo {
  transition: 0.3s;
  height: 35px;
  margin: 0 2rem 0 1rem;
  width: 35px;
  background-color: #0a90dd;
  border-radius: 50%;
  box-shadow: 0 0 10px 3px #1976d2;
}
.smallBackgroundLogo:hover {
  box-shadow: 0 0 30px 2px #fff;
}

.tinyLogo {
  height: 46px;
  transform: translate(-12.8px, -6px);
  cursor: pointer;
  transition: 0.3s;
}
.tinyLogo:hover {
  transform: translate(-12.5px, -8px) scale(1);
}

.backgroundLogo {
  margin: 0.5rem 0 0.3rem 0;
  transition: 0.3s;
  height: 40px;
  width: 40px;
  background-color: #0a90dd;
  border-radius: 50%;
  box-shadow: 0 0 10px 3px #1976d2;
}
.backgroundLogo:hover {
  box-shadow: 0 0 18px 2px #fff;
}

.backgroundHomeLogo {
  transition: 0.3s;
  height: 60px;
  width: 60px;
  background-color: #0a90dd;
  border-radius: 50%;
  box-shadow: 0 0 10px 3px #1976d2;
}
.backgroundHomeLogo:hover {
  box-shadow: 0 0 18px 2px #fff;
}

.homeLogo {
  height: 76px;
  transform: translate(-19.5px, -8px);
  cursor: pointer;
  transition: 0.3s;
}
.homeLogo:hover {
  transform: translate(-19.5px, -10px) scale(1);
}

.bigBackgroundLogo {
  transition: 0.3s;
  height: 100px;
  width: 100px;
  background-color: #0a90dd;
  border-radius: 50%;
  cursor: pointer;
}
.bigBackgroundLogo:hover {
  box-shadow: 0 0 30px 2px #fff;
}

.bigLogo {
  height: 120px;
  transform: translate(-28px, -8px);
  cursor: pointer;
  transition: 0.3s;
}
.bigLogo:hover {
  transform: translate(-28px, -10px) scale(1);
}

.navbarGBLogo {
  width: 240px;
  height: 80px;
  cursor: pointer;
  transform: translate(0%, 4%);
  place-self: center;
  display: flex;
  justify-content: start;
}

.darkGBLogo {
  width: 80%;
  cursor: pointer;
}

.gbHomeLogo {
  width: 28vw;
  height: 9vw;
  cursor: pointer;
}
@media screen and (max-width: 1064px) {
  .gbHomeLogo {
    width: 240px;
    height: 80px;
  }
}

.slogan {
  font-family: "c";
}

.GBA {
  margin: 0 auto;
  font-size: 2.3rem;
  font-weight: 100;
}
@media screen and (max-width: 1064px) {
  .GBA {
    font-size: 1.8rem;
  }
}

.backgroundLogo-user {
  /* goodbye */
  color: white;
  font-family: "Cinzel Decorative", cursive;
  /* infinity symbol it is often adapted by others to be used in expressions of never-ending love or individual empowerment (tied to the concept of being eternal and, thus, unbreakable) */
  /* The infinity symbol holds a deep meaning for spirituality, love, beauty, and power. The mysticism of our past and the possibility for an eternal future */
  justify-self: start;
  margin-inline-start: 10px;
  cursor: pointer;
  font-size: 45px;
  font-weight: 600;
  text-decoration: none;
  border: none;
  transform: translate(5%, 10%);
  white-space: nowrap;
}

.main-nav-active-users .nav-links-user {
  text-decoration: underline;
  color: #fff;
}

.nav-menu-user {
  /* navbar links */
  align-items: center;
  display: grid;
  grid-template-columns: repeat(6, auto);
}

#navMenuIcon {
  color: white;
  cursor: pointer;
  font-size: 2rem;
  margin-inline-end: 1rem;
}

.menu__userXIcon {
  color: white;
  transform: translate(30%, 4%);
}

.nav-links-user {
  /* navbar links */
  font-size: 1.2rem;
  color: white;
  text-decoration: none;
  font-weight: bold;
  align-items: center;
  background-color: transparent;
  border: none;
  background-repeat: no-repeat;
  cursor: pointer;
  outline: none;
  letter-spacing: 0.5px;
}

.fa-bars {
  /* icon */
  color: white;
  transform: translate(5%, 8%);
}

.nav-links-user-mobile {
  /* mobile navbar */
  outline: none !important;
  align-items: center;
  /* font-family: 'Monserrat', sans-serif; */
  padding: 5px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  background: #2ecc71;
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  font-size: 23px;
  justify-content: flex-start;
}

.userNameHandle {
  color: #fff;
}

.userNameHandle:hover,
.nav-links-user-mobile:hover {
  transition: all 0.3s ease-out;
  color: black;
  transition: 250ms;
}

.menu-icon-user {
  /* icons on large screens */
  display: none;
}

#iosCancel {
  font-size: calc(18px + 6 * (100vw - 320px) / 680);
  position: relative;
  left: 5px;
  bottom: 2px;
}

#logOutIcon {
  font-size: calc(23px + 6 * (100vw - 320px) / 680);
  position: relative;
  bottom: 2px;
}

.confirmationTitle {
  font-weight: 250;
  background-color: #000;
  color: #fff;
}

#logOutDialog {
  font-size: calc(23px + 6 * (100vw - 320px) / 680);
  margin: 2rem 0 0 0;
  font-weight: 250;
}

#logOutDialog2 {
  font-size: calc(15px + 6 * (100vw - 320px) / 680);
  margin: 1rem 0 0 0;
  font-weight: 150;
}

#cancelDialogActionBtn {
  background-color: #2c3e50;
}

#logOutDialogBtn {
  font-size: 1.4rem;
  font-weight: bold;
  font-size: 18px;
  background-color: #2ecc71;
  font-family: "Monserrat";
  color: #fff;
  box-shadow: 0 2px 2px #ccc;
}
#logOutDialogBtn:hover {
  text-decoration: underline;
  background-color: #2ecc71;
}

.focusedTab {
  border-bottom: 1px solid #fff;
}

@media screen and (max-width: 700px) {
  .navMobileDiv {
    height: 70px;
  }
  .smallBackgroundLogo {
    margin: 0 1.74rem 0 0.6rem;
  }
}
@media screen and (max-width: 480px) {
  #navMenuIcon {
    margin-inline-end: 0.5rem;
  }
}
@media screen and (max-width: 400px) {
  #navMenuIcon {
    font-size: 1.5rem;
  }
  .navbarContent {
    height: 60px;
  }
  .GBA {
    font-size: 1.4rem;
  }
  .navMobileText {
    font-size: 16px;
  }
  .navbarGBLogo {
    width: 180px;
    height: 60px;
    cursor: pointer;
    transform: translate(0%, 4%);
    place-self: center;
    display: flex;
    justify-content: start;
  }
  .logo {
    height: 46px;
    transform: translate(-15px, -8px);
  }
  .logo:hover {
    transform: translate(-15px, -10px) scale(1);
  }
  .backgroundLogo {
    height: 30px;
    width: 30px;
  }
  .smallBackgroundLogo {
    height: 25px;
    width: 25px;
    margin: 0 1.67rem 0 0.62rem;
  }
  .tinyLogo {
    height: 34px;
    transform: translate(-9.7px, -5px);
  }
  .tinyLogo:hover {
    transform: translate(-12.5px, -7px) scale(1);
  }
}
@media screen and (max-width: 300px) {
  .navbarGBLogo {
    width: 120px;
    height: 40px;
    cursor: pointer;
    transform: translate(0%, 4%);
    place-self: center;
    display: flex;
    justify-content: start;
  }
  .navMobileText {
    font-size: 0px;
    width: 30px;
  }
  #navMobileAction {
    min-width: 0;
  }
}
html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  margin: 0;
  padding: 0;
  width: 100%;
}

.css-v3zyv7-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  height: auto;
  min-height: 1.4375em;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
}

.css-xzkq1u-MuiFormHelperText-root {
  font-size: 1.2rem !important;
  margin: 3px 0 0 !important;
}

.cursorDefault {
  cursor: default;
}

.capitalize {
  text-transform: capitalize;
}

.textInherit {
  text-transform: initial;
}

.textStart {
  text-align: start;
}

.alignLastCenter {
  -moz-text-align-last: center;
       text-align-last: center;
}

.grabbing {
  pointer-events: auto !important;
  cursor: grabbing !important;
  cursor: -webkit-grabbing !important;
}

input:-webkit-autofill::first-line {
  font-size: 16px;
}

input#email {
  font-size: 16px;
}

.navMobileIcon {
  color: #1e87cd;
}

.headerColor {
  color: #1e1e1e;
}

.appBlue {
  color: #0a90dd;
}

#appBlue {
  color: #0a90dd;
}

.wordbreak {
  word-break: break-all;
}

.prewrap {
  white-space: pre-wrap;
}

.hidden {
  overflow: hidden;
}

.link {
  color: #0a90dd;
  cursor: pointer;
  font-weight: bold;
}

a {
  text-decoration: none;
  background-color: transparent;
}

hr {
  border: 0;
  clear: both;
  display: block;
  width: 100%;
  background-color: #ccc;
  height: 1px;
}

.lightHr {
  border-top: 1px solid #dad9d9;
}

.flex,
.flexColumnMedia {
  display: flex;
}

.flexReverse {
  display: flex;
  flex-direction: column-reverse;
}

.flexStatic {
  flex-direction: row-reverse;
}

.max1000w {
  max-width: 1000px;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.center {
  text-align: center;
  justify-content: center;
  width: 100%;
}

.marginAuto {
  margin: 0 auto;
}

.alignItems {
  align-content: baseline;
}

.alignCenter {
  text-align: center;
  justify-content: center;
  align-items: center;
}

.alignItemsCenter {
  align-items: center;
}

.alignSelfStart {
  align-self: start;
}

.alignBaseline {
  align-items: baseline;
  align-content: baseline;
}

.spaceAround {
  justify-content: space-around;
}

.spaceBetween {
  justify-content: space-between;
}

.spaceStart {
  justify-content: start;
}

.spaceEvenly {
  justify-content: space-evenly;
}

.light {
  font-weight: 240;
}

.pullupH {
  margin-bottom: -0.5rem;
}

#mgYH,
.mgYH {
  margin: 0.5rem 0;
}

.mgYH- {
  margin: -0.5rem 0;
}

#mgl {
  margin-inline-start: 1rem;
}

#mglh {
  margin-inline-start: 0.5rem;
}

#mgr {
  margin-inline-end: 1rem;
}

#mgrH {
  margin-inline-end: 0.5rem;
}

.mgrH {
  margin-inline-end: 0.5rem;
}

#mgt {
  margin-top: 1rem;
}

#mgt2 {
  margin-top: 2rem;
}

#mgb {
  margin-bottom: 1rem;
}

#mgY {
  margin: 1rem 0;
}

#mgX {
  margin: 0 1rem;
}

.contentRight {
  justify-content: right;
}

.flexAuto {
  display: flex;
  margin: 0 auto;
}

.mg0 {
  margin: 0;
}

.mgH {
  margin: 0.5rem;
}

.mg {
  margin: 1rem;
}

.mg2 {
  margin: 2rem;
}

.marginAuto {
  margin: 0 auto;
}

.mgX {
  margin: 0 1rem;
}

.mgX2 {
  margin: 0 2rem;
}

.mgY {
  margin: 1rem 0;
}

.mgl3px {
  margin-inline-start: 3px;
}

.mgr3px {
  margin-inline-end: 3px;
}

.mgr4px {
  margin-inline-end: 4px;
}

.mgl4px {
  margin-inline-start: 4px;
}

.mgl6px {
  margin-inline-start: 6px;
}

.mgb3px {
  margin-bottom: 3px;
}

.mgY2 {
  margin: 2rem 0;
}

.mgl {
  margin-inline-start: 1rem;
}

.mgl2px {
  margin-inline-start: 2.1px;
}

.mgl4px- {
  margin-inline-start: -4px;
}

.mgl5px {
  margin-inline-start: 5px;
}

.mgr {
  margin-inline-end: 1rem;
}

.mgb-2Media {
  margin-bottom: -2rem;
}

.mglOnly {
  margin: 0 0 0 1rem;
}

.mgtOnly {
  margin: 1rem 0 0 0;
}

.mgtOnlyH {
  margin: 0.5rem 0 0 0;
}

.mgbOnly {
  margin: 0 0 1rem 0;
}

.mgtOnly {
  margin: 1rem 0 0 0;
}

.pdtExcept {
  padding: 0 1rem 1rem 1rem;
}

.pdbExcept {
  padding: 1rem 1rem 0 1rem;
}

.pdrExcept {
  padding: 1rem 0 1rem 1rem;
}

.pdlExcept {
  padding: 1rem 1rem 1rem 0;
}

.pd2b1 {
  padding: 2rem 2rem 1rem 2rem;
}

.pd2Y {
  padding: 2rem 0;
}

.mgbExcept {
  margin: 1rem 1rem 0 1rem;
}

.mgb- {
  margin-bottom: -1rem;
}

.mgbH- {
  margin-bottom: -0.5rem;
}

.mgt {
  margin-top: 1rem;
}

.mgtMedia {
  margin-top: 1rem;
}

.mgt1H {
  margin-top: 1.5rem;
}

.mgtH {
  margin-top: 0.5rem;
}

.mgt2 {
  margin-top: 2rem;
}

.mgt3 {
  margin-top: 3rem;
}

.mgt4 {
  margin-top: 4rem;
}

.mgt5 {
  margin-top: 5rem;
}

.mgb {
  margin-bottom: 1rem;
}

.mgbH {
  margin-bottom: 0.5rem;
}

#mglH,
.mglH {
  margin-inline-start: 0.5rem;
}

.mgb2 {
  margin-bottom: 2rem;
}

.pd {
  padding: 1rem;
}

.mgX- {
  margin: 0 -1rem;
}

.mgXHalf {
  margin: 0 0.5rem;
}

.pdH {
  padding: 0.5rem;
}

.pdt08 {
  padding-top: 0.8rem;
}

.pdX {
  padding: 0 1rem;
}

.pdXMedia {
  padding: 0 1rem;
}

.pdXH {
  padding: 0 0.5rem;
}

.pdX2 {
  padding: 0 2rem;
}

.pdY {
  padding: 1rem 0;
}

.pd0 {
  padding: 0;
}

.pd0Dialog {
  padding: 0 !important;
}

.pd2 {
  padding: 2rem;
}

.pd3 {
  padding: 3rem;
}

#pdt,
.pdt {
  padding-top: 1rem;
}

.pdtH {
  padding-top: 0.5rem;
}

.pdt4px {
  padding-top: 2px;
}

.pdt1H {
  padding-top: 1.5rem;
}

.pdt2 {
  padding-top: 2rem;
}

.pdt3 {
  padding-top: 3rem;
}

.pdb {
  padding-bottom: 1rem;
}

.pdbOnly {
  padding: 0 0 1rem 0;
}

.pdb2 {
  padding-bottom: 2rem;
}

.pdb3px {
  padding-bottom: 3px;
}

#pdl,
.pdl {
  padding-inline-start: 1rem;
}

.pdlH {
  padding-inline-start: 0.5rem;
}

.pdr {
  padding-inline-end: 1rem;
}

.pdrH {
  padding-inline-end: 0.5rem;
}

.pdbH {
  padding-bottom: 0.5rem;
}

.noDeco {
  text-decoration: none;
}

.underline {
  text-decoration: underline;
}

.iconWhite {
  color: #fff;
}

#white {
  color: #fff;
}

#iconBlue,
.iconBlue {
  color: #0a90dd;
}

.iconViolet {
  color: #8e44ad;
}

.iconLightBlue {
  color: #acd3fa;
}

#iconGreen,
.iconGreen {
  color: #2fcc71;
}

.backgroundGreen {
  background-color: #2fcc71;
}

.backgroundBlue {
  background-color: #0a90dd;
}

.iconLightGreen {
  color: #19d367;
}

.iconDarkGreen {
  color: #10ac84;
}

.iconRed,
#red {
  color: red;
}

.iconLightGray {
  color: #95a5a6;
}

.iconMidBlue {
  color: #2d92ff;
}

.iconOrange {
  color: #ff9500;
}

.iconYellow {
  color: #ffbb00;
}

.iconGray2 {
  color: #34495e;
}

.white {
  color: #fff;
}

.dark {
  color: #000;
}

.max1200 {
  max-width: 1200px;
}

.card {
  background-color: #fff;
  box-shadow: 0 0 5px 2px #ccedfa;
  transition: 0.3s;
  border-radius: 5px;
  max-width: 1200px;
  margin: 0 auto;
}

.max1200 {
  max-width: 1200px;
  display: flex;
  margin: 0 auto;
}

.authLoadingCard {
  max-width: 600px;
  margin: 0 auto;
  border-radius: 8px;
}

.cardReport {
  background-color: #fff;
  box-shadow: 0 0 5px 2px #ccedfa;
  transition: 0.3s;
  border-radius: 5px;
}
.cardReport:hover {
  box-shadow: 0 2px 15px #97bbc9;
}

.card:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.cardLetter {
  background-color: #fff;
  box-shadow: 0 0 5px 2px #ccedfa;
  transition: 0.3s;
  border-radius: 5px;
  max-width: 1600px;
  margin: 0 auto;
}

#cardMainIcon {
  place-self: center;
  background-color: #2ecc71;
  color: #fff;
  height: 60px;
  width: 60px;
}

#cardMainIconSize {
  width: 40px;
  height: 40px;
}

#cardMainIcon2 {
  place-self: center;
  background-color: #2ecc71;
  color: #fff;
  height: 50px;
  width: 50px;
}

#cardMainIconSize2 {
  width: 30px;
  height: 30px;
}

.serif {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 1.8rem;
}

@font-face {
  font-family: Rye;
  src: url(../scss/Rye-Regular.ttf);
}
@font-face {
  font-family: c;
  src: url(../scss/CinzelDecorative-Regular.ttf);
}
@font-face {
  font-family: cTitle;
  src: url(../scss/Cinzel.ttf);
}
.title {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
  font-size: calc(2vw + 2vh + 2vmin);
  font-size: 48px;
}

.titleHello {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
  font-size: calc(2vw + 2vh + 2vmin);
  font-size: 30px;
  margin: 1rem 0;
}

.subtitle {
  font-size: 32px;
  font-weight: 100;
  text-align: center;
}

.textTitle {
  font-size: 24px;
  font-weight: 200;
}

.text {
  font-size: 20px;
  font-weight: 200;
}

p {
  font-size: 20px;
  font-weight: 200;
}

.breakWord {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.transarent {
  background: transparent;
}

.blueLink {
  color: #0a90dd;
}

#link {
  text-decoration: none;
  color: #0a90dd;
}
#link:hover {
  text-decoration: underline;
}

#brightLink {
  text-decoration: none;
  color: #0996fb;
}

.authQuestion {
  text-align: center;
  font-size: 20px;
  margin: 0.5rem auto;
}

.authQuestion2 {
  text-align: center;
  font-size: 20px;
  margin: 0.5rem auto;
}

.textTopic {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1.75;
  letter-spacing: 0.00938em;
}

.topTranslate {
  transform: translate(0, -1px);
}

.topTranslate2 {
  transform: translate(0, -2px);
}

.topTranslate3 {
  transform: translate(0, -3px);
}

.topTranslate5 {
  transform: translate(0, -5px);
}

.topTranslate6 {
  transform: translate(0, -6px);
}

.topTranslate8 {
  transform: translate(0, -8px);
}

.topTranslate9 {
  transform: translate(0, -9px);
}

.topTranslate10 {
  transform: translate(0, -10px);
}

.registeredLoading {
  position: relative;
  top: -12px;
}

.bottomLeftTranslate2 {
  transform: translate(-2px, 2px);
}

.bottomLeftTranslate4 {
  transform: translate(-4px, 4px);
}

.bottom2LeftTranslate6 {
  transform: translate(-6px, 2px);
}

.leftTranslate3 {
  transform: translate(-3.5px, 0);
}

.leftTranslate4 {
  transform: translate(-4px, 0);
}

.leftTranslate78 {
  transform: translate(-7.8px, 0);
}

.leftTranslate20 {
  transform: translate(-1.2rem, 0);
}

#rightTranslate3,
.rightTranslate3 {
  transform: translate(3px, 0);
}

#rightTranslate4,
.rightTranslate4 {
  transform: translate(4px, 0);
}

.rightTranslate5 {
  transform: translate(5px, 0);
}

#rightTranslate7,
.rightTranslate7 {
  transform: translate(7px, 0);
}

.bottomTranslate {
  transform: translate(0, 1px);
}

#bottomTranslate2,
.bottomTranslate2 {
  transform: translate(0, 2px);
}

#bottomTranslate3,
.bottomTranslate3 {
  transform: translate(0, 3px);
}

.bottomTranslate4 {
  transform: translate(0, 4px);
}

.bottomTranslate5 {
  transform: translate(0, 5px);
}

.bottomTranslate6 {
  transform: translate(0, 6px);
}

.bottomTranslate7 {
  transform: translate(0, 7px);
}

.bottomTranslate8 {
  transform: translate(0, 8px);
}

.bottomTranslate3rem {
  transform: translate(0, 3rem);
}

.leftTranslate2 {
  transform: translate(-2px, 0);
}

.rightTranslate {
  transform: translate(2px, 0);
}

.MuiAlert-icon {
  align-self: center;
}

.italic {
  font-style: italic;
  font-weight: bold;
}

.lightBold {
  font-weight: 600;
}

.bold {
  font-weight: 800;
}

.normalWeight {
  font-weight: 500;
}

.flexEnd {
  display: flex;
  justify-content: end;
}

.alignSelfCenter {
  align-self: center;
}

.pd2B1 {
  padding: 2rem 2rem 1rem 2rem;
}

.fullWidth {
  width: 100%;
}

.noBorderRadius {
  border-radius: 0;
}

.onlyBorderBottom {
  border-radius: 0 0 4px 4px;
}

.browserLogo {
  width: 40px;
  margin: 0.5rem 0;
}

.wbc {
  background-color: white;
}

.dbc {
  background-color: #242424;
}

#zindex1 {
  z-index: 1;
}

#bold {
  font-weight: bold;
}

#underlineHover:hover,
.underlineHover:hover {
  text-decoration: underline;
}

.pdMedia {
  padding: 1rem;
}

.pdY2Media {
  padding: 2rem 0;
}

.pdt2Media {
  padding: 2rem 0 0 0;
}

.pdb2Media {
  padding: 0 0 2rem 0;
}

.pd2Media {
  padding: 2rem;
}

.pd2b1Media {
  padding: 2rem 2rem 1rem 2rem;
}

.pd2t1b1Media {
  padding: 1rem 2rem 1rem 2rem;
}

.pdbExceptMedia {
  padding: 1rem 1rem 0 1rem;
}

.pdtFollowBtn {
  padding-top: 1rem;
}

.mglMedia {
  margin-inline-start: 1rem;
}

.mgrMedia {
  margin-inline-end: 1rem;
}

.pdl2Media {
  padding-inline-start: 2rem;
}

.pdYH {
  padding: 0.5rem 0;
}

.flexMediaColumn {
  display: flex;
}

@media screen and (max-width: 1064px) {
  .flexColumnMedia {
    flex-direction: column;
  }
  .textTopic {
    font-size: 1.2rem;
  }
  .mgb-2Media {
    margin-bottom: -1rem;
  }
  .pdMedia {
    padding: 0.5rem;
  }
  .pdY2Media {
    padding: 1rem 0;
  }
  .pdt2Media {
    padding: 1rem 0 0 0;
  }
  .pdb2Media {
    padding: 0 0 1rem 0;
  }
  .pd2Media {
    padding: 1rem;
  }
  .pdtFollowBtn {
    padding-top: 0.4rem;
  }
  .pdl2Media {
    padding-inline-start: 1rem;
  }
  .pd2b1Media,
  .pd2t1b1Media {
    padding: 1rem;
  }
  .pdbExceptMedia {
    padding: 0.5rem 0.5rem 0 0.5rem;
  }
  .mglMedia {
    margin-inline-start: 0.5rem;
  }
  .mgrMedia {
    margin-inline-end: 0.5rem;
  }
  .pdtOnlyMedia {
    padding-top: 1rem;
  }
  #pdtHOnlyMedia {
    padding-top: 0.5rem;
  }
  #mgbOnlyMedia,
  .mgbOnlyMedia {
    margin-bottom: 0.5rem;
  }
  #mgYOnlyMedia {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .actionDialogText {
    font-size: 1rem;
  }
  .pdXMedia {
    padding: 0 0.5rem;
  }
  #cardMainIconSize {
    width: 30px;
    height: 30px;
  }
  #cardMainIcon {
    height: 50px;
    width: 50px;
  }
  #cardMainIcon2 {
    height: 40px;
    width: 40px;
  }
  #cardMainIconSize2 {
    width: 25px;
    height: 25px;
  }
  .pdb3 {
    padding-bottom: 3.5rem;
  }
}
@media screen and (max-width: 900px) {
  .flexMedia {
    display: flex;
    align-items: baseline;
  }
  .flexMedia > a > .text {
    font-size: 20px;
    margin-inline-start: 5px;
  }
  .title {
    font-size: 36px;
  }
  .subtitle {
    font-size: 28px;
  }
  .textTitle {
    font-size: 20px;
  }
  .text {
    font-size: 18px;
  }
  p {
    font-size: 18px;
  }
  .pdt08OnlyMedia {
    padding-top: 0.5rem;
  }
}
.small {
  font-size: 1rem;
}

@media screen and (max-width: 480px) {
  .mgtMedia {
    margin-top: 0.5rem;
  }
  .title {
    font-size: 32px;
  }
  .subtitle {
    font-size: 26px;
  }
  #cardMainIconSize {
    width: 25px;
    height: 25px;
  }
  #cardMainIcon {
    height: 40px;
    width: 40px;
    margin-top: 0.5rem;
  }
}
@media screen and (max-width: 400px) {
  .flexColumnAudioBtn {
    flex-direction: column;
  }
  .authQuestion {
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 0.5rem;
  }
  .authQuestion2 {
    font-size: 16px;
  }
  .flexMedia > a > .text {
    font-size: 15px;
    margin-inline-start: 5px;
  }
  .cardLetter {
    box-shadow: none;
    border-radius: 0%;
  }
  .title {
    font-size: 28px;
  }
  .subtitle {
    font-size: 24px;
  }
}
@media screen and (max-width: 350px) {
  .flexMediaColumn {
    flex-direction: column;
  }
}
.small {
  font-size: 1rem;
}

.invert {
  filter: invert(100%);
}

.mgb6px {
  margin-bottom: 8px;
}

.mgt3px {
  margin-top: 6px;
}

.mgt4px {
  margin-top: 10px;
}

.mgrExcept {
  margin: 1rem 0 1rem 1rem;
}

.mgr6px- {
  margin-inline-end: -6px;
}

.mgr10px- {
  margin-inline-end: -10px;
}

.bottomTranslate10 {
  transform: translate(0, 10px);
}

.iconSettingGray {
  color: rgb(180, 179, 179);
}

#alignLast {
  -moz-text-align-last: center;
       text-align-last: center;
}

.minFont {
  font-size: 1.1rem;
}

.mgXH {
  margin: 0 0.5rem;
}

.width70 {
  width: 70%;
}

.width30 {
  width: 30%;
  display: flex;
  justify-content: end;
  float: inline-end;
}

.justifyCenter {
  justify-content: center;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.footerContainer {
  width: 100%;
  background-color: #242424;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cursiveSubtitle {
  font-size: 30px;
  font-family: cursive;
  color: #fff;
  text-align: center;
}

.footerLinks {
  width: 100%;
  max-width: 800px;
  display: flex;
  justify-content: space-evenly;
}

.footerNav {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 0 auto;
}

.footerNavItems {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  width: 50%;
  box-sizing: border-box;
  align-items: center;
}

.footerNavItems h3 {
  white-space: nowrap;
  margin-bottom: 16px;
}

.footerNavItems > h3 {
  color: #fff;
}

.footerNavItems a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footerNavItems a:hover {
  color: #0996e7;
  transition: 0.3s;
}

.socialMediaLogos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 800px;
  margin: 30px auto 0;
  overflow: hidden;
}

.footerGBLogo {
  width: 230px;
  height: 75px;
  cursor: pointer;
  transform: translate(0%, 4%);
  place-self: center;
  display: flex;
  justify-content: start;
}

.socialMediaLogoSize {
  height: 1.7rem;
}

.facebook:hover {
  background: #3b5998;
  border-radius: 15%;
  transition: 0.3s;
}

.twitterX:hover {
  background: #000000;
  border-radius: 15%;
  transition: 0.3s;
}

.instagram:hover {
  background: #d319a4;
  border-radius: 15%;
  transition: 0.3s;
}

.linkedin:hover {
  background: #0866c2;
  border-radius: 15%;
  transition: 0.3s;
}

.tiktok:hover {
  background: #000000;
  border-radius: 15%;
  transition: 0.3s;
}

.youtube:hover {
  background: #f3090d;
  border-radius: 15%;
  transition: 0.3s;
}

.footerNavItems > a {
  font-size: 1.2rem;
}

.max600 {
  width: 80vw;
  max-width: 650px;
}

@media screen and (max-width: 820px) {
  .footer-subscription {
    padding-bottom: 0px;
    color: #fff;
  }
  .btn {
    width: 100%;
  }
  .social-icons {
    margin-bottom: 3.5rem;
  }
  .footer-subscription-heading {
    /* margin-bottom: 24px; */
    font-size: 28px;
  }
  .cursiveSubtitle {
    font-size: 26px;
  }
  .footerGBLogo {
    width: 195px;
    height: 65px;
  }
  .sloganFooter {
    font-size: 1.3rem;
  }
  .mobileNavHeight {
    height: 50px;
  }
}
.animation {
  text-align: center;
  position: relative;
  font-family: sans-serif;
  text-transform: uppercase;
  margin: 2rem 1rem 1rem;
  font-size: 1rem;
  letter-spacing: 3px;
  overflow: hidden;
  background: linear-gradient(90deg, rgb(2, 2, 2), rgb(0, 0, 0), rgb(6, 163, 139), rgb(0, 0, 0), rgb(2, 2, 2));
  background-size: 90%;
  animation: animate 4s linear infinite;
  -webkit-background-clip: text;
  background-clip: "text";
  -webkit-text-fill-color: rgba(8, 255, 24, 0.469);
  cursor: default;
}

@media screen and (max-width: 600px) {
  .social-logo {
    font-size: 2rem;
  }
  .social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
  }
  .footerLinks {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  .socialMediaLogoSize {
    height: 1rem;
  }
  .cursiveSubtitle {
    font-size: 24px;
    margin: 0 1rem;
    font-family: cursive;
    color: #fff;
    text-align: center;
  }
  .animation {
    font-size: 10px;
  }
}
@keyframes animate {
  0% {
    background-position: -400%;
  }
  100% {
    background-position: 500%;
  }
}
.postHeaderDark {
  background-color: #121212;
}

.postHeaderLight {
  background-color: #e2e2e2;
}

.postActions {
  padding: 0.5rem;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1e1e1e;
}

#postCard {
  max-width: 1000px;
  padding: 5px;
  width: 100%;
  margin: 1.5rem auto;
}

#verifiedBadgePost {
  transform: translate(8px, 2px);
}

#verifiedBadgeComment {
  transform: translate(2px, 6px);
}

#imageContain {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

#lcSpan {
  cursor: pointer;
  margin-inline-end: 0.7rem;
}

#cursorPointer {
  cursor: pointer;
}

.commentBtn {
  background: none;
  border: none;
}

#cIcon {
  color: #fff;
  border: none;
}

#cIconDisabled {
  color: #ccc;
  border: none;
  margin-inline-start: 0.3rem;
}

.postBtnActions {
  display: flex;
  justify-content: space-evenly;
  margin: 0.5rem 0 1rem 0;
}

.MuiList-padding {
  padding: 0px !important;
}

#dotMenuText {
  font-size: 3rem !important;
}

.postBody {
  font-size: 2.125rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.235;
  letter-spacing: 0.00735em;
  text-align: start;
  margin: 1rem 0.5rem 0rem 0.5rem;
}

.postHeader {
  display: flex;
  justify-content: space-between;
}

#p_ReleaseDate {
  border-radius: 0px;
  font-size: 1.6rem;
  color: #fff;
  background-color: #0a90dd;
}

.MuiAlert-message {
  overflow: hidden !important;
}

#postText {
  border-bottom: 3px solid #000;
  border-right: 3px solid #000;
  border-left: 3px solid #000;
  color: #000;
  padding: 0.5rem 1rem;
}

.previewVisibility {
  color: #fff;
  place-self: center;
  display: flex;
}

.previewVisibility > span {
  margin-inline-end: 6px;
}

#loadingCommentProgress {
  font-size: 10px;
}

.loadingPostComments {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.releasedDate {
  background-color: #0a90dd;
  padding: 1rem;
  color: #fff;
  text-align: center;
  font-weight: bold;
}

.alignViews {
  display: flex;
  justify-content: end;
  align-items: center;
}

.postBodyContent {
  height: 226px;
  overflow: hidden;
  transition: max-height 0.3s ease;
  position: relative;
}

.postBodyContent.showAllContent {
  height: 100%;
}

.postBodyContent.showAllContent:after {
  height: 100%;
  position: relative;
}

.postBodyContent:after {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  height: 11%;
  border-radius: 0 0 3px 3px;
  bottom: 0px;
  background: linear-gradient(180deg, rgba(139, 167, 32, 0) 0%, rgba(161, 161, 161, 0.849) 100%);
  pointer-events: none;
}

.commentBodyContent {
  height: 136px;
  overflow: hidden;
  transition: max-height 0.3s ease;
  position: relative;
}

.commentBodyContent.showAllContent {
  height: 100%;
}

.commentBodyContent.showAllContent:after {
  height: 100%;
  position: relative;
}

.commentBodyContent:after {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  height: 11%;
  border-radius: 0 0 3px 3px;
  bottom: 0px;
  background: linear-gradient(180deg, rgba(139, 167, 32, 0) 0%, rgba(161, 161, 161, 0.849) 100%);
  pointer-events: none;
  height: 7%;
}

@media screen and (max-width: 1064px) {
  #p_ReleaseDate {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 900px) {
  #postCard {
    padding: 0px;
  }
}
.react-read-more-read-less,
.react-read-more-read-less-more,
.react-read-more-read-less-less {
  color: #0a90dd;
  cursor: pointer;
}
.react-read-more-read-less:hover,
.react-read-more-read-less-more:hover,
.react-read-more-read-less-less:hover {
  text-decoration: underline;
}

.pdPostHeader {
  padding: 1rem 0.5rem 0 1rem;
}

.PostS_PersonIcon {
  background: url("../util/Images/blank-profile-picture.jpg") center;
  background-size: 125px;
  background-position: 50% 23%;
  width: 75px;
  height: 90px;
  filter: invert(1%) sepia(1%) saturate(1%) hue-rotate(1deg) brightness(1000%) contrast(80%);
  margin-inline-end: 16px;
  border-radius: 50%;
  box-shadow: 0px 0px 5px 2px #ccc;
}

.ProfileS_Icon {
  background: url("../util/Images/blank-profile-picture.jpg") center;
  background-size: 500px;
  background-position: 50% 13%;
  width: 18.3vw;
  height: 400px;
  max-width: 400px;
  filter: invert(8%) sepia(73%) saturate(3207%) hue-rotate(170deg) brightness(98%) contrast(100%);
  margin: 9px 16px 9px 9px;
  border-radius: 50%;
  box-shadow: 0px 0px 5px 2px #ccc;
}

.PS_Date_Small {
  display: block;
  height: 14px;
  background-color: #fff;
  margin-bottom: 10px;
  width: 60px;
}

.PS_cardContent {
  width: 100%;
  flex-direction: column;
  background-color: black;
}

.PS_Date_FullLine {
  height: 15px;
  width: 90%;
  background-color: #fff;
  margin-bottom: 10px;
}

.PS_Date_HalfLine {
  height: 15px;
  width: 50%;
  background-color: #fff;
}

.QS_Report_FullLine {
  width: 84px;
  margin: 0 5px;
  background-color: #000000;
}

.QS_Report_MidLine {
  width: 40px;
  margin-inline-start: 5px;
  background-color: #000000;
}

.ul_list {
  list-style: none;
  position: relative;
  margin: 0;
  padding: 0;
}

.querySkeleton {
  display: flex;
  flex-direction: row;
}

.searchImgBackground {
  filter: invert(8%) sepia(73%) saturate(3207%) hue-rotate(170deg) brightness(98%) contrast(100%);
}

#circularPostLoader {
  position: absolute;
  top: 50%;
  left: 48%;
  color: #0a90dd;
}

#likeIcon_SK {
  font-size: 1.5rem;
  min-width: 1px;
  color: #fff;
}

#skeletonDate {
  height: 15px;
  width: 220px;
}
@media screen and (max-width: 1064px) {
  #skeletonDate {
    width: 190px;
  }
}

#dialogBar {
  background-color: #1e1e1e;
  display: flex;
  justify-content: space-between;
  padding: 0 8px 0 16px;
}
@media (max-width: 300px) {
  #dialogBar {
    padding: 0 4px 0 8px;
  }
}

#closeDialogButton {
  padding: 0;
  color: #fff;
  font-size: 2rem;
}
#closeDialogButton:hover {
  transform: rotate(95deg);
  cursor: pointer;
}
@media (max-width: 1064px) {
  #closeDialogButton {
    font-size: 1.6rem;
  }
}
@media (max-width: 600px) {
  #closeDialogButton {
    font-size: 1.4rem;
  }
}

#userAvatar {
  background-color: #fff;
  border-radius: 50%;
  width: 66px;
  box-shadow: 0px 0px 7px 1px rgb(39, 166, 183);
  transition: 0.3s;
  -o-object-fit: cover;
     object-fit: cover;
  margin-inline-end: 1rem;
  height: 70px;
}
#userAvatar:hover {
  box-shadow: 1.5px 10px 10px 1px rgb(39, 166, 183);
}

.userAvatarLetter {
  font-size: 2rem;
  color: #000;
  font-weight: bold;
  font-family: "Lexend";
  font-weight: 300;
  position: relative;
  top: 2px;
}

.notificationReadLight:hover {
  background-color: rgb(246, 246, 246);
}

.notificationReadDark:hover {
  background-color: rgb(85, 85, 85);
}

.newNot {
  background-color: rgba(0, 162, 255, 0.236);
}

.newNot:hover {
  background-color: rgba(0, 255, 251, 0.155);
}

#notDialog {
  z-index: 1200 !important;
}

.followList {
  display: flex;
  align-items: center;
  -moz-text-align-last: start;
       text-align-last: start;
  width: 100%;
  min-width: 20vw;
}
.followList:hover {
  cursor: pointer;
}

.hashtagList {
  display: flex;
  border: none;
  background: transparent;
  align-items: center;
  -moz-text-align-last: start;
       text-align-last: start;
  width: 100%;
  min-width: 20vw;
}
.hashtagList:hover {
  cursor: pointer;
}

#hashtagPaper {
  display: flex;
  justify-content: center;
  padding: 0.4rem;
  align-items: center;
}

.new {
  color: #0a90dd;
}

.listIcon {
  margin-inline-start: 1rem;
  transform: translate(0, 2px);
  white-space: nowrap;
  align-self: center;
}

.followBtn {
  border: none;
  font-family: "Monserrat", sans-serif;
  padding: 5px 10px;
  white-space: nowrap;
  border-radius: 5px;
  font-weight: bold;
  font-size: 20px;
  color: #fff;
  transition: 0.4s;
}
@media screen and (max-width: 1064px) {
  .followBtn {
    font-size: 15px;
  }
}

.nowrap {
  white-space: nowrap;
}

.allowNotifications {
  background-color: #535353;
  color: #fff;
}

.deletingComment {
  background-color: rgba(254, 1, 1, 0.176);
}

.lightBlueComment {
  background-color: rgba(0, 153, 255, 0.119);
}

.lightWarningComment {
  background-color: rgba(255, 115, 0, 0.186);
}

.commentReplyReport {
  background-color: rgba(0, 153, 255, 0.267);
}

.commentReport {
  background-color: rgba(53, 136, 142, 0.485);
}

.additional {
  border-radius: 3px;
  animation: highlight 5000ms ease-out;
}

@keyframes highlight {
  0% {
    background-color: rgba(0, 153, 255, 0.267);
  }
  100% {
    background-color: inherit;
  }
}
#C_userDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.adminCommentData {
  display: flex;
  justify-content: space-around;
}

.commentsUserImg {
  border-radius: 50%;
  width: 66px;
  height: 70px;
  margin-inline-end: 1rem;
  box-shadow: 0.5px 1px 10px 1px rgb(113, 111, 111);
}

.commentBody {
  margin: 0;
}

.commentDateFromNow {
  opacity: 66%;
  margin-inline-end: 0.3rem;
}

.submittingSpan {
  color: #0056b3;
}

.red {
  color: #e70707;
}

#userRowCircularLoading {
  align-self: center;
  margin-inline-end: 6.2px;
  position: relative;
}

.bird-logo-notifications {
  width: 100px;
}

.userTabs {
  max-width: 989px;
  margin: 0 auto;
}
@media screen and (max-width: 1000px) {
  .userTabs {
    padding: 0 1rem;
    width: 100%;
  }
}

.listItemText {
  flex: 1 1 auto;
  align-self: center;
}

.listThumbnail {
  align-self: center;
  box-shadow: 0 0 3px 1px #ccedfa;
  height: 40px;
}

.listItemSpan {
  font-size: 1.4rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  margin-inline-end: 5px;
  display: flex;
  margin-bottom: 0;
}
@media (max-width: 1064px) {
  .listItemSpan {
    font-size: 1.2rem;
  }
}

.imageEnableNots {
  width: 100%;
  margin: 0 auto;
  display: flex;
  max-width: 800px;
  height: 500px;
  box-shadow: 0 0px 5px 5px #ccedfa;
}

.imageEnableNotsFirefox {
  width: 60%;
  margin: 0 auto;
  display: flex;
  max-width: 800px;
  height: 500px;
  box-shadow: 0 0px 5px 5px #ccedfa;
}

.orderCommentsIcon {
  margin-inline-start: 1.05rem;
}
@media screen and (max-width: 340px) {
  .orderCommentsIcon {
    margin-inline-start: 0.9rem;
  }
}

#hover {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  pointer-events: none;
  height: 100%;
  width: 0;
  mix-blend-mode: overlay;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.smallText {
  font-size: 16px;
}

#noImgTransparent {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transform: translate(0px, -2vw);
}

#innerEmojiPicker {
  margin-inline-start: -2.2rem;
  position: relative;
  right: 3.5rem;
}

.flexBrowsers {
  display: flex;
}
@media (max-width: 850px) {
  .flexBrowsers {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.loadingRecipient {
  padding: 3px 0;
}

.pdComment {
  padding: 0 0.5rem 0 1rem;
}

.grabCursor {
  cursor: grab;
}

.grabCursor:active {
  cursor: grabbing;
}

.react-wrapper {
  display: flex;
  justify-content: center;
}

.react-player {
  display: flex;
  max-height: 80vh;
  overflow: hidden;
  height: 100% !important;
  width: 100% !important;
  background-color: #000;
}

.react-player > video {
  max-height: 80vh;
  background-color: #000;
}

.yt-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.yt-player {
  position: absolute;
  top: 0;
  left: 0;
}

.width1-3 {
  width: 33.3%;
}

.widthHalf {
  width: 50%;
}

.commentActions {
  position: relative;
  z-index: 3;
}

#centerSelect {
  display: flex;
  align-items: center;
}

@media (max-width: 1100px) {
  .MuiGrid-grid-md-5 {
    flex-grow: 0 !important;
    max-width: 41.666667% !important;
    flex-basis: 41.666667% !important;
  }
}
.profileDetails {
  font-size: 1.3rem;
  margin-inline-start: 5px;
}

/* Bio input EditDetails.js*/
.userFirstLetter {
  position: relative;
  top: 166px;
  font-size: 4rem;
  margin-top: -5.66rem;
  font-family: "Lexend";
  font-weight: 300;
}

.circularProgressOnProfileImg {
  color: #3498db;
  position: absolute;
}

.handleProfile {
  font-size: 30px;
  font-weight: 300;
}

.staticProfileContainer {
  width: 100%;
  max-width: 2000px;
  display: flex;
  margin: 0 auto;
}
@media screen and (max-width: 900px) {
  .staticProfileContainer {
    display: flex;
    flex-direction: column;
  }
  .staticProfileContainer > :nth-child(1) {
    order: 2; /* Set the order of the first child to be 2 */
  }
  .staticProfileContainer > :nth-child(2) {
    order: 1; /* Set the order of the second child to be 1 */
  }
  .staticProfileContainer > :nth-child(3) {
    order: 3; /* Set the order of the third child to be 3 */
  }
}

.staticAction {
  padding: 0 1rem;
  margin-top: 5rem;
}
@media screen and (max-width: 900px) {
  .staticAction {
    margin: 0;
  }
}

.staticSection1 {
  width: 33.3%;
}
@media screen and (max-width: 900px) {
  .staticSection1 {
    width: 100%;
  }
}

.staticSection2 {
  width: 33.3%;
}
@media screen and (max-width: 900px) {
  .staticSection2 {
    width: 100%;
  }
}

.staticSection3 {
  width: 33.3%;
}
@media screen and (max-width: 900px) {
  .staticSection3 {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  #profileDottedMenu {
    position: absolute;
    top: 10px;
    right: 6px;
  }
  #profileAction {
    position: absolute;
    top: 10px;
    left: 14px;
  }
}
.staticFollowers {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
  margin-top: 4.7rem;
}
@media screen and (max-width: 900px) {
  .staticFollowers {
    padding: 0 0.5rem 0 0.5rem;
    margin: 0;
    flex-direction: row;
    justify-content: space-around;
  }
}

.username-sp {
  font-size: 1.8rem;
  font-family: "Lexend";
}

.username-sp-mobile {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-family: "Lexend";
  text-align: start;
  padding-inline-start: 1.02rem;
}
@media screen and (max-width: 1064px) {
  .username-sp-mobile {
    font-size: 1.2rem;
  }
}

.name-sp {
  font-size: 2rem;
  font-family: "Lexend";
}
@media screen and (max-width: 900px) {
  .name-sp {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 400px) {
  .name-sp {
    font-size: 1.5rem;
  }
}

.detailsIcon {
  color: #0a90dd;
}

.uploadProfilePost {
  margin: 0 auto;
  max-width: 992px;
}
@media screen and (max-width: 1064px) {
  .uploadProfilePost {
    margin: -1rem auto 0;
  }
}

#usernameIcon {
  font-size: 1.4rem;
}
@media screen and (max-width: 1064px) {
  #usernameIcon {
    font-size: 1.2rem;
  }
}

.pdrOrder {
  padding-inline-end: 0.6rem;
}

.imgDialogEdit {
  position: absolute;
  right: 6px;
  top: 12px;
}
@media screen and (max-width: 900px) {
  .imgDialogEdit {
    right: 4px;
    top: 5px;
  }
}

.dialog-title-btn {
  display: flex;
  justify-content: space-between;
}

.close-icon {
  position: relative;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.titles-container {
  text-align: center;
  margin: 0 2rem !important;
}

.center-file-input {
  margin-top: 2rem;
  -moz-text-align-last: center;
       text-align-last: center;
  display: flex;
  flex-direction: column;
}

.post-button {
  margin: 2rem auto !important;
  font-size: 2rem !important;
}

#form-dialog-txt {
  padding-bottom: 0;
  margin-bottom: -1rem;
}

#ed_editPictureButton {
  position: absolute;
  bottom: 8px;
  left: 1rem;
  font-family: "Moneserrat";
  font-weight: bold;
  background: #2c3e50;
  color: #fff;
  font-size: 18px;
}
#ed_editPictureButton:hover {
  background: #000;
}

#ed_deletePictureButton {
  color: #fff;
  transition: 0.4s;
  background: #2c3e50;
}
#ed_deletePictureButton:hover {
  background-color: #ff5252;
}

#muiAlert {
  font-size: 1.4rem;
  place-items: center;
}

#smallMuiAlert {
  font-size: 1.2rem;
  place-items: center;
}
#smallMuiAlert .MuiAlert-icon {
  font-size: 1.25rem;
}

#edTitleIcons {
  font-size: 26px;
  color: #0a90dd;
  transform: translate(-3px, 5px);
}

#edFolderIcon {
  font-size: 26px;
  color: #0a90dd;
  transform: translate(-3px, 5px);
  transform: translate(-1px, 5px);
}

#ed_dialogRemoveTitle {
  text-align: center;
  background-color: #ff3838;
  color: #fff;
  font-size: calc(30px + 6 * (100vw - 320px) / 680);
  padding: 0.7rem;
}

#addIcon {
  position: relative;
  bottom: 1px;
}

.hexColorContent {
  align-items: baseline;
  max-width: 200px;
  margin-top: 10px;
}

#undoHexIcon {
  cursor: pointer;
  transform: translate(-10px, 10px);
}

@media screen and (max-width: 900px) {
  .react-colorful {
    width: 100px !important;
    height: 100px !important;
  }
  #muiAlert {
    font-size: 1.3rem;
    padding: 0 1rem;
  }
  #smallMuiAlert {
    font-size: 1.1rem;
    place-items: center;
  }
  #smallMuiAlert .MuiAlert-icon {
    font-size: 1rem;
  }
}
#iconDottedMenu {
  font-size: 1.5rem;
}

#commentDots > span {
  vertical-align: super;
}

#dotText {
  margin: 0 0 0 10px;
}

#DButton {
  color: red;
  font-family: "Monserrat";
  font-weight: "bold";
  transition: 0.4s;
  transform: translate(0, -2px);
}

#lockOpenIcon {
  font-size: 32px;
  position: relative;
  bottom: 5px;
  margin-top: 9px;
}

#successConfirmDialog {
  font-weight: bold;
  font-size: 18px;
  background-color: #2fcc71;
  color: #fff;
  font-family: "Monserrat";
  box-shadow: 0px 2px 2px #ccc;
}
#successConfirmDialog:hover {
  background-color: #218b4d;
}

#badgeForumIcon {
  font-size: 15px;
  color: #3f3f3f;
  transform: translate(0px, 5px);
}

#mediaDotted {
  font-size: 1.5rem;
}
@media screen and (max-width: 1024px) {
  #mediaDotted {
    font-size: 1.3rem;
  }
}

#postDottedMenu {
  padding: 4px;
}

#LB_postButtons {
  border: none;
  background-color: transparent;
  padding-inline-end: 0.5rem;
}

@media screen and (max-width: 500px) {
  #lIcon {
    font-size: 1.3rem;
  }
}
#postDialogBar {
  display: flex;
  justify-content: space-between;
  background-color: #242424;
  padding: 0 8px 0 12px;
}
@media screen and (max-width: 1064px) {
  #postDialogBar {
    padding: 0 12px 0 12px;
  }
}

#confirmDialogBar {
  display: flex;
  justify-content: space-between;
  background-color: #242424;
  padding: 0 12px;
}

#popoverDialogBar {
  padding: 0;
  margin: 0;
  min-height: auto;
}

.PD_profileImage {
  max-width: 200px;
  height: 200px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}

.PD_spinnerDiv {
  background-image: url(./images/skygb.jpg);
  background-position: center; /* Center the image */
  text-align: center;
  margin: 0;
  padding: 0;
  height: 60vh;
}
@media screen and (max-width: 1064px) {
  .PD_spinnerDiv {
    height: 100vh;
  }
}

.PD_loadingDiv {
  position: relative;
  top: 30%;
  color: #fff;
}

.pd_likeLogic {
  margin-inline-end: 0.7rem;
}

.deletedPost_PD {
  width: 100%;
  margin: 1rem 0;
}

#searchInput {
  padding-inline-start: 2.3rem;
}

#notAllowed:hover {
  cursor: not-allowed;
}

.containerTextfieldIcon {
  position: relative;
  display: inline-block;
  width: 100%;
}

#search__InputIcon {
  z-index: 1;
  position: absolute;
  top: 23px;
  left: 9.6px;
}

#queryUserAuthProgress {
  align-self: center;
  text-align: center;
  margin: 12.4px;
  position: relative;
}

#circularProgressQuery {
  display: flex;
  place-content: center;
  text-align: center;
}

.moreResultsBtn {
  border: none;
  color: #0b95ff;
  display: flex;
  margin: 0 auto;
  text-decoration: underline;
  background-color: transparent;
  padding: 1rem 0;
  font-size: 20px;
}
.moreResultsBtn:hover {
  color: #0178d3;
}

.s_resetInput {
  align-self: center;
}
.s_resetInput:hover {
  cursor: pointer;
}

.pdSearchbar {
  padding: 10px 10px 4px 10px;
}

.toastTime {
  color: rgba(255, 255, 255, 0.7);
  margin-inline-start: 5px;
}

.Toastify__toast {
  margin-bottom: 0.7rem !important;
}
@media screen and (max-width: 704px) {
  .Toastify__toast {
    margin-bottom: 0.1rem !important;
  }
}

.Toastify__toast-container--top-left {
  max-width: 500px;
  top: 5.1rem !important;
  left: 0.4rem !important;
}
@media screen and (min-width: 1064px) {
  .Toastify__toast-container--top-left {
    width: 23.7vw !important;
  }
}
@media screen and (max-width: 1064px) {
  .Toastify__toast-container--top-left {
    top: 5.2rem !important;
  }
}
@media screen and (max-width: 704px) {
  .Toastify__toast-container--top-left {
    width: 100% !important;
    top: 4.8rem !important;
    left: 0 !important;
    right: 0 !important;
    padding: 0 !important;
  }
}
@media screen and (max-width: 603px) {
  .Toastify__toast-container--top-left {
    width: 100% !important;
    top: 4.8rem !important;
    left: 0 !important;
    right: 0 !important;
    padding: 0 !important;
  }
}
@media screen and (max-width: 480px) {
  .Toastify__toast-container--top-left {
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    padding: 0 !important;
  }
}

.Toastify__toast-container--top-right {
  top: 5.1rem !important;
  right: 0.4rem !important;
}
@media screen and (max-width: 1064px) {
  .Toastify__toast-container--top-right {
    top: 5.2rem !important;
  }
}

.Toastify__progress-bar-theme--colored,
.Toastify__progress-bar--info {
  background-color: #0a90dd !important;
}

.Toastify__progress-bar-theme--colored,
.Toastify__progress-bar--success {
  background-color: #19d367 !important;
}

.Toastify__progress-bar-theme--colored,
.Toastify__progress-bar--warning {
  background-color: #ff9500 !important;
}

.Toastify__progress-bar-theme--colored,
.Toastify__progress-bar--error {
  background-color: red !important;
}

.Toastify__toast-body {
  font-size: 1.2rem;
  padding: 0 6px 6px 3px !important;
}

.Toastify__toast-theme--colored.Toastify__toast--default,
.Toastify__toast-theme--light {
  color: #3c3c3c !important;
}

.Toastify__toast-theme--dark {
  background: #1e1e1e !important;
  color: var(--toastify-text-color-dark);
}

.toastThumbnail {
  box-shadow: 0 0 2px 1px #868686;
  width: 24px;
  height: 24px;
}
@media screen and (max-width: 1064px) {
  .toastThumbnail {
    width: 22px;
    height: 22px;
  }
}
@media screen and (max-width: 700px) {
  .toastThumbnail {
    width: 20px;
    height: 20px;
  }
}

@media screen and (min-width: 1064px) {
  .postTitles {
    padding-bottom: 1rem;
  }
}

#addPictureBtn_MP {
  font-family: "Moneserrat";
  background: #2c3e50;
  color: #fff;
  font-size: 18px;
}
#addPictureBtn_MP:hover {
  background: #000;
}

#stepper_MP {
  padding: 8px 4px;
}

#stepper_MP > button {
  font-weight: bold;
}
@media screen and (min-width: 1064px) {
  #stepper_MP > button {
    font-size: 1.4rem;
    pointer-events: all !important;
  }
  #stepper_MP > button:disabled {
    cursor: not-allowed;
  }
}

.fileSuccess_MP {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem auto;
}

.imageToUpload_MP {
  width: 100%;
  height: 80%;
  margin-top: 1.5rem;
  -o-object-fit: contain;
     object-fit: contain;
  position: relative;
}

.alertSuccess_MP {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: none;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  background-color: rgb(237, 247, 237);
  display: flex;
  padding: 6px 16px;
  color: rgb(30, 70, 32);
  justify-content: space-between;
}

.alertSuccess_MP > span {
  align-self: center;
}

.checkBoxIcon {
  margin-inline-end: 0.3rem;
}

.MuiInput-underline:after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border-bottom: 2px solid #3398db !important;
  pointer-events: none;
}

#textArea_MP {
  padding: 1.5rem 1rem;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
  min-height: 400px;
  max-height: 1200px;
}

#darkTextArea_MP {
  background: none;
  color: #fff;
  padding: 1.5rem 1rem;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
  min-height: 400px;
  max-height: 1200px;
}

#darkCommentInput {
  background: none;
  color: #fff;
}

#mp_Paper {
  min-width: 80vw;
  max-width: 1200px;
  margin: auto;
  position: relative;
}
@media screen and (max-width: 1064px) {
  #mp_Paper {
    border-radius: 0;
  }
}

#mp_Paper > h5 {
  font-size: 26px;
  font-weight: 200;
  margin: 1rem;
}

.mp_title {
  text-align: center;
  font-size: 1.6rem;
  font-weight: 240;
}

.errorRed {
  color: red;
}

#mp_loaderRecipientAvatars {
  background-color: #fff;
}

#mp_avatarGroup {
  justify-content: center;
  margin-top: 1rem;
}

#mp_paperRecipient {
  padding: 0 1rem 0 1rem;
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 600px;
  box-shadow: 0px 0px 2px 1px #6dccfa;
  transition: 0.5s;
}
#mp_paperRecipient:hover {
  box-shadow: 0px 0px 2px 1px #1cb3ff;
  background-color: #f6fbfd;
}
#mp_paperRecipient:hover .mp_paperUserRecipientHandle {
  text-decoration: underline;
}
#mp_paperRecipientDelete {
  color: #f02d2a;
  cursor: pointer;
}

#gbl_paperRecipient:has(#mp_paperRecipientDelete:hover) {
  box-shadow: 0px 0px 2px 1.5px #f02d2a;
}

.userSelection_MP {
  text-align: -webkit-center;
}

.userSelection_MP > h5 {
  margin-top: 1rem;
}

.mp_paperUserRecipientHandle {
  display: flex;
  font-size: 20px;
  padding: 0.5rem;
}

.mp_paperUserRecipientHandle > h5 {
  align-items: center;
  margin: 0;
  font-weight: 600;
}

.mp_paperUserRecipientHandle > h5 > span {
  align-items: center;
  font-weight: 400;
}

.mp_containerAvatarHandle {
  align-items: center;
  display: flex;
  padding: 0.5rem 0;
}

.formHourContainer_mp,
.formDateContainer_mp {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

#formHourSelector_mp {
  width: 100%;
  max-width: 66px;
  margin-inline-end: 2rem;
}

#formHourSelector_mp > label {
  color: #0a90dd;
}

#meridiem_mp {
  transform: translate(0, 4px);
}

.textfieldDatePicker {
  cursor: pointer;
}

.postPreviewContainer {
  margin: 0 auto;
}

#mp_dateIcon {
  transform: translate(-8px, 4px);
}

#mp_dateIconClicker {
  transform: translate(-7px, 4px);
  cursor: pointer;
}

.dropArea_inactive {
  position: relative;
  display: flex;
  height: 30vh;
  border-radius: 3px;
  transition: 0.2s;
  border: 3px dashed #2196f3;
}
.dropArea_inactive.is-active {
  background-color: rgba(2, 117, 217, 0.05);
}

.dropArea_active {
  position: relative;
  display: flex;
  height: 30vh;
  border-radius: 3px;
  transition: 0.2s;
  border: 3px solid #2196f3;
}
.dropArea_active.is-active {
  background-color: rgba(2, 117, 217, 0.05);
}

.file-msg {
  font-size: large;
  font-weight: 300;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

@media screen and (max-width: 1064px) {
  #stepper_MP > button {
    font-size: 1rem;
    padding: 0;
  }
  .dropArea_inactive {
    height: 42vh;
  }
}
.config_Button {
  border: none;
  background-color: transparent;
}

#configSettingsIcon {
  color: #fff;
  cursor: pointer;
}

#iconAccordion {
  font-size: 1.6rem;
  margin-inline-end: 0.5rem;
}

#smallIconAccordion {
  font-size: 1.2rem;
  margin-inline-end: 0.5rem;
}

#iconSettings {
  font-size: 1.6rem;
  transform: translate(0.15px, 0);
}

.pdAccordion {
  padding: 1rem;
}

@media screen and (max-width: 500px) {
  #iconAccordion {
    font-size: 1.2rem;
  }
  #iconSettings {
    font-size: 1.2rem;
  }
  .pdAccordion {
    padding: 0.5rem;
  }
}
#typographySettings {
  font-size: 1.1rem;
  font-family: system-ui;
  align-items: center;
  display: flex;
  margin-inline-end: 1rem;
}

#typographySecondary {
  font-size: 1.1rem;
}

.marginAlertsY {
  margin: 0.5rem 0;
}

.marginAlerts {
  margin-bottom: 1rem;
}

.inputText {
  font-weight: 240;
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: auto;
  font-size: 1.3rem;
}
@media screen and (min-width: 800px) {
  .inputText {
    font-size: 1.4rem;
  }
}

.inputTextTitle {
  font-size: 1.6rem;
  font-weight: 240;
  display: flex;
  justify-content: center;
  align-items: center;
}

.textfieldIconGray {
  color: #62625d;
}

.textfieldIconBlue {
  color: #0771b3;
}

#textfieldIconError {
  color: #eb081e;
}

.customInputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.startAdornment {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  background-color: #ccc;
  height: 61px;
  width: 50px;
}

#customSuccessButton {
  font-family: "Monserrat", sans-serif;
  padding: 5px 20px;
  cursor: pointer;
  font-weight: bold;
  color: #fff;
  transition: 0.4s;
  display: flex;
  justify-content: center;
  background-color: #2fcc71;
}

#customDisabledButton {
  font-family: "Monserrat", sans-serif;
  padding: 5px 20px;
  cursor: pointer;
  font-weight: bold;
  color: #fff;
  transition: 0.4s;
  display: flex;
  justify-content: center;
  color: #ccc;
  cursor: not-allowed;
}

#customCancelButton {
  font-family: "Monserrat", sans-serif;
  padding: 5px 20px;
  cursor: pointer;
  font-weight: bold;
  color: #fff;
  transition: 0.4s;
  display: flex;
  justify-content: center;
  background-color: #1e1e1e;
}

#filterButton {
  font-family: "Monserrat", sans-serif;
  padding: 5px 20px;
  cursor: pointer;
  font-weight: bold;
  color: #fff;
  transition: 0.4s;
  display: flex;
  justify-content: center;
  background-color: #1e1e1e;
  box-shadow: 0 0 5px 2px #0a90dd;
}

#customInfoButton {
  font-family: "Monserrat", sans-serif;
  padding: 5px 20px;
  cursor: pointer;
  font-weight: bold;
  color: #fff;
  transition: 0.4s;
  display: flex;
  justify-content: center;
  background-color: #0a90dd;
}

#customFilterButton {
  font-family: "Monserrat", sans-serif;
  padding: 5px 20px;
  cursor: pointer;
  font-weight: bold;
  color: #fff;
  transition: 0.4s;
  display: flex;
  justify-content: center;
  background-color: #111111;
  padding: 0px 20px;
  transition: 0.4s;
}

#customDangerButton {
  font-family: "Monserrat", sans-serif;
  padding: 5px 20px;
  cursor: pointer;
  font-weight: bold;
  color: #fff;
  transition: 0.4s;
  display: flex;
  justify-content: center;
  background-color: #ff3838;
}

#customGrayButton {
  font-family: "Monserrat", sans-serif;
  padding: 5px 20px;
  cursor: pointer;
  font-weight: bold;
  color: #fff;
  transition: 0.4s;
  display: flex;
  justify-content: center;
  background-color: #2c3e50;
}

#customYellowButton {
  font-family: "Monserrat", sans-serif;
  padding: 5px 20px;
  cursor: pointer;
  font-weight: bold;
  color: #fff;
  transition: 0.4s;
  display: flex;
  justify-content: center;
  background-color: #dd9904;
}

#customButton:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

#iconButton {
  font-size: 2rem;
}

#mediumIconBtn {
  font-size: 1.6rem;
}

@media screen and (max-width: 905px) {
  #customButton:hover {
    color: #fff;
  }
  #iconButton {
    font-size: 1.6rem;
  }
}
#deleteIconButton {
  color: #ff3838;
}

#buttonColor {
  color: #0a90dd;
}

#hoverWhite {
  transition: 0.3s ease-in-out;
  border-radius: 50%;
}
#hoverWhite:hover {
  cursor: pointer;
  box-shadow: 0px 0px 11px #fff !important;
  border-radius: 0;
}

#navImg {
  width: 46px;
  height: 52px;
  border-radius: 50%;
  transition: 0.3s ease-in-out;
}
#navImg:hover {
  cursor: pointer;
  box-shadow: 0px 0px 4px #6dccfa !important;
}
@media (max-width: 400px) {
  #navImg {
    width: 35px;
    height: 42px;
  }
}

#navBottomImg {
  width: 37px;
  height: 42px;
  border-radius: 50%;
  transition: 0.3s ease-in-out;
}

#hoverBlue {
  box-shadow: 0px 0px 10px 2px #6dccfa;
  transition: 0.4s;
  display: flex;
  margin: 0 auto 0.5rem auto;
  border-radius: 50%;
}
#hoverBlue:hover {
  cursor: pointer;
  border-radius: 0;
}

#hoverBlueProfile {
  border-radius: 50%;
  box-shadow: 0px 0px 11px 5px #6dccfa !important;
  transition: 0.4s;
  margin: 0 auto;
}
#hoverBlueProfile:hover {
  cursor: pointer;
  box-shadow: 0px 0px 11px 3px #6dccfa !important;
  border-radius: 0;
}

#hoverBlueNavbar {
  border-radius: 50%;
  box-shadow: 0px 0px 5px 2px #6dccfa !important;
  transition: 0.4s;
}
#hoverBlueNavbar:hover {
  cursor: pointer;
  box-shadow: 0px 0px 11px 3px #6dccfa !important;
  border-radius: 0;
}

.postUserFL {
  font-weight: 900;
  font-family: "Lexend";
  margin: 0 auto 0.2rem auto;
}

.blueBoxShadow {
  box-shadow: 0px 0px 5px 2px #6dccfa;
}

#imgMainProfile {
  border-radius: 50%;
  align-self: center;
  transition: 0.4s;
  width: 35vw;
  height: 40.1vw;
  display: flex;
  margin: 0 auto;
  max-width: 260px;
  max-height: 300px;
}
#imgMainProfile:hover {
  cursor: pointer;
  border-radius: 0;
}

#imgEditDetails {
  border-radius: 50%;
  align-self: center;
  transition: 0.4s;
  width: 30vw;
  height: 35.1vw;
  display: flex;
  margin: 0 auto;
  max-width: 200px;
  max-height: 240px;
}
#imgEditDetails:hover {
  cursor: pointer;
  border-radius: 0;
}

#imgMainProfileExpanded {
  align-self: center;
  display: flex;
  margin: 0 auto;
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}

#noImgMainProfileExpanded {
  border-radius: 50%;
  align-self: center;
  transition: 0.4s;
  display: flex;
  margin: 0 auto;
  width: 17vw;
  height: 20vw;
  max-width: 230px;
  max-height: 280px;
  margin: 1.5rem;
}
#noImgMainProfileExpanded:hover {
  cursor: pointer;
  border-radius: 0;
}

#imgProfile {
  border-radius: 50%;
  align-self: center;
  transition: 0.4s;
  display: flex;
  margin: 1rem auto 0;
  width: 15vw;
  height: 18vw;
  max-width: 230px;
  max-height: 280px;
}
#imgProfile:hover {
  cursor: pointer;
  border-radius: 0;
}

#notCommentImg {
  border-radius: 50%;
  align-self: center;
  transition: 0.4s;
  height: 28px;
  width: 25px;
}
#notCommentImg:hover {
  cursor: pointer;
  border-radius: 0;
}

#listImg {
  border-radius: 50%;
  align-self: center;
  transition: 0.4s;
  width: 66px;
  height: 74px;
}
#listImg:hover {
  cursor: pointer;
  border-radius: 0;
}

#mediaImg {
  border-radius: 50%;
  align-self: center;
  transition: 0.4s;
  width: 56px;
  height: 64px;
}
#mediaImg:hover {
  cursor: pointer;
  border-radius: 0;
}
@media screen and (max-width: 700px) {
  #mediaImg {
    width: 50px;
    height: 58px;
  }
}
@media screen and (max-width: 400px) {
  #mediaImg {
    width: 46px;
    height: 54px;
  }
}

#mediaImgSkeleton {
  border-radius: 50%;
  align-self: center;
  transition: 0.4s;
  width: 56px;
  height: 64px;
}
#mediaImgSkeleton:hover {
  cursor: pointer;
  border-radius: 0;
}
@media screen and (max-width: 700px) {
  #mediaImgSkeleton {
    width: 50px;
    height: 58px;
  }
}
@media screen and (max-width: 400px) {
  #mediaImgSkeleton {
    width: 46px;
    height: 54px;
  }
}
#mediaImgSkeleton:hover {
  cursor: pointer;
  border-radius: 50%;
}

#toastImg {
  border-radius: 50%;
  align-self: center;
  transition: 0.4s;
  width: 40px;
  height: 45px;
}
#toastImg:hover {
  cursor: pointer;
  border-radius: 0;
}
@media screen and (max-width: 700px) {
  #toastImg {
    width: 30px;
    height: 35px;
  }
}
@media screen and (max-width: 400px) {
  #toastImg {
    width: 22px;
    height: 27px;
  }
}

#listCommentImg {
  border-radius: 50%;
  align-self: center;
  transition: 0.4s;
  align-self: inherit;
  width: 40px;
  height: 45px;
}
#listCommentImg:hover {
  cursor: pointer;
  border-radius: 0;
}
@media screen and (max-width: 340px) {
  #listCommentImg {
    width: 35px;
    height: 40px;
  }
}

#listReplyImg {
  border-radius: 50%;
  align-self: center;
  transition: 0.4s;
  align-self: inherit;
  width: 35px;
  height: 40px;
}
#listReplyImg:hover {
  cursor: pointer;
  border-radius: 0;
}
@media screen and (max-width: 340px) {
  #listReplyImg {
    width: 30px;
    height: 35px;
  }
}

.graphic_phones {
  width: 100%;
  height: auto;
  max-width: 500px;
}

.rc-mentions {
  display: inline-block;
  position: relative;
  white-space: pre-wrap;
}

.rc-mentions-measure {
  position: absolute;
  left: 50px;
  top: 0;
  pointer-events: none;
  color: transparent;
}

.rc-mentions-dropdown {
  position: absolute;
  z-index: 100;
  background-color: #e1e1e1;
  --arrow-x: 0px;
  --arrow-y: -2px;
  inset: 189.594px auto auto 146.688px;
  box-sizing: border-box;
}

.rc-mentions-dropdown-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.rc-mentions-dropdown-menu-item {
  cursor: pointer;
}

.rc-mentions {
  font-size: 20px;
  border-radius: 6px;
}

.darkMentions {
  background-color: #1e1e1e;
}

.mentionsPadding {
  padding: 4px 8px;
}

.rc-mentions-dropdown-menu-item-active {
  background: #e6f7ff;
}

.rc-mentions-dropdown-menu-item-disabled {
  opacity: 0.5;
  display: none;
}

/* .list--hover {
  background: #fafbfc;
} */
.soft-half {
  padding: 7px !important;
}

.pointer {
  cursor: pointer;
}

.rc-mentions > textarea {
  width: 100%;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  position: relative;
  cursor: text;
  padding: 16.5px 14px;
  font-size: 1.3rem;
  overflow: hidden;
  display: flex;
  resize: none;
  box-sizing: border-box;
  margin: 0;
  align-items: center;
  outline: none !important;
  border-radius: 4px;
  box-shadow: inner 0 0 4px rgba(0, 0, 0, 0.2);
  border: 1.5px solid rgba(240, 228, 228, 0.7058823529);
}
.rc-mentions > textarea:hover {
  border: 1.5px solid rgba(186, 186, 186, 0.7058823529);
}
.rc-mentions > textarea:focus {
  border: 1.5px solid #0586e9;
}
@media screen and (max-width: 400px) {
  .rc-mentions > textarea {
    padding: 16.5px 10px;
  }
}

.editor {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 2em;
  box-shadow: inset 0px 1px 8px -3px #ababab;
  background: #fefefe;
}

.editor :global(.public-DraftEditor-content) {
  min-height: 140px;
}

.pdCommentForm {
  padding: 1rem 1rem 0.6rem;
}
@media screen and (max-width: 1064px) {
  .pdCommentForm {
    padding: 0.5rem 0.5rem 0.3rem;
  }
}

.terms__Container {
  background-color: #373636;
}

.termsCard {
  padding: 0 1rem;
}

.labelBlock {
  display: inline-block;
  width: 100%;
  margin-top: 1rem;
}

.reportCheckboxes {
  display: flex;
  flex-direction: column;
}

.errorSpan {
  color: red;
}

.email-instructions {
  margin-bottom: 1rem;
}

.forgotPasswordSubtitle {
  font-size: 30px;
  font-weight: 200;
  margin: 5px 0 10px 0;
}

.skyBackground {
  background-image: url(./images/skygb.jpg);
  background-attachment: fixed;
  flex: 1;
}

.skyBackgroundFill {
  background-image: url(./images/skygb.jpg);
  background-size: cover;
  min-height: 100vh;
  flex: 1;
}

.homeContent {
  padding: 2rem 0;
}

.centeredMiddle {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.grecaptcha-badge {
  visibility: hidden;
}

.MuiGrid-grid-xs-12 {
  margin: 0rem 1rem 0rem 1rem;
}

#backgroundAuth {
  background: linear-gradient(90deg, rgb(52, 223, 184) 0%, rgb(0, 196, 203) 52%, rgb(0, 212, 255) 100%);
  flex: 1;
}

.subtitle2 {
  font-size: 23px;
  font-weight: 200;
  margin-top: 1rem;
}

#authProgressVerify {
  color: #2fcc71;
  margin: 2rem auto;
}

#email-helper-text,
#password-helper-text,
#confirmPassword-helper-text,
#inputHandle-helper-text,
#titleReport-helper-text,
#descriptionReport-helper-text {
  font-size: 1.3rem;
  margin: 2px 0 0 0;
}

/* popover */
.popover {
  max-width: 100%; /* Max Width of the popover (depending on the container!) */
}

/* arrow */
.bs-popover-auto[x-placement^=bottom] > .arrow::after,
.bs-popover-bottom > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: lightgray;
}

.arrow {
  transform: translate3d(124px, 0px, 0px) !important;
  position: absolute;
  left: 0px;
}

.popover-header::before {
  border-bottom: 1px solid lightgray !important;
}

#icon_hover:hover {
  cursor: pointer;
}

.validator_div {
  float: left;
  text-align: start;
}

#validator__tag {
  width: 100%;
  -moz-text-align-last: left;
       text-align-last: left;
  margin: 0.1rem 0;
}

.su__Requirements {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media screen and (max-width: 956px) {
  .popover {
    width: 330px; /* Max Width of the popover (depending on the container!) */
  }
  .Login-resize-31,
  .Login-resize-7 {
    font-size: 0.4rem;
  }
}
@media screen and (max-width: 500px) {
  #mbr_h {
    font-size: 2.4rem;
  }
  #mbr {
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 330px) {
  .popover {
    width: 250px; /* Max Width of the popover (depending on the container!) */
  }
  #mbr {
    font-size: 1.5rem;
  }
}
.termsCheckbox {
  transform: translate(-10px, 0);
  display: flex;
  align-items: center;
}

.alerts {
  margin-bottom: 1rem;
}

.recaptcha {
  margin: -1rem 1rem 1rem 0;
  color: #fff;
}

#birthDateAuth {
  font-size: 16px;
  margin-inline-start: 4px;
  position: relative;
  top: 2px;
}

.userFeed__Container {
  max-width: 2333px;
}

.centerPosts__MediaQuery {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

.profile__Container {
  width: 90%;
  margin: 1rem auto 0 auto;
}

#alertUploadProgressPost {
  width: 100%;
}

#alertUploadProgressPost > .MuiAlert-message {
  width: 100%;
  text-align: start;
}

#tabs {
  font-weight: bold;
  font-family: system-ui;
  font-size: 1rem;
}
#tabs:hover {
  color: #0a90dd;
}

.offlineSubtitle {
  color: #000000;
  font-family: arial;
  font-weight: 300;
  font-size: calc(20px + 0.8823529412vw);
  margin-bottom: 1rem;
}

.offlineContainerGB {
  text-align: center;
  position: absolute;
  margin: 0 auto;
  bottom: 1rem;
  left: 0;
  right: 0;
}

.offlineLogo {
  display: flex;
  width: 30%;
  margin: 1rem auto;
}

.adminHeader {
  text-align: center;
  padding-bottom: 1rem;
}

.adminReportPanel {
  text-align: center;
}

.reportPanelOptions {
  box-shadow: 0 0.5px 5px #0b76b8;
  width: 55vw;
  margin: 1rem auto;
  padding: 5px 0 10px;
  display: flex;
  justify-content: space-evenly;
  border-radius: 20%;
  transition: 0.3s ease-in;
}
.reportPanelOptions:hover {
  box-shadow: 0 0.5px 6px #76b2d7;
}

.reportSecondContainer {
  border-left: 1px solid #ccc;
  padding: 1rem;
  max-width: 300px;
}

.reportDialogContent {
  padding: 1rem;
  background: linear-gradient(90deg, rgb(52, 223, 184) 0%, rgba(0, 196, 203, 0.678) 52%, rgb(0, 212, 255) 100%);
}

.reportDialogTable {
  text-align: center;
  border: 1px solid #ccc;
  box-shadow: 0 0.5px 6px #ccc;
  width: 100%;
}

.reportDialogTableRow {
  text-align: center;
  margin: 0.3rem auto;
  width: 100%;
}

.trTitles {
  background-color: rgba(11, 118, 184, 0.4156862745);
  color: #fff;
  font-weight: bolder;
}

.trContent {
  background-color: rgba(11, 118, 184, 0.144);
  color: #fff;
}

.tableRow1 {
  width: 40%;
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
}

.tableRow2 {
  width: 20%;
  color: #fff;
  font-size: 1.2rem;
}

.tableRow3 {
  width: 40%;
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
}

.tableRow {
  display: flex;
  justify-content: space-around;
  width: 100%;
  color: #fff;
  box-shadow: 2px 2px 2px #000;
}

#center {
  display: flex;
  margin: 0 auto;
}

.sliceReportContainer {
  width: 50vw;
  overflow: hidden;
  white-space: nowrap;
  margin-inline-end: 1rem;
}

#reportInputRadio {
  margin: 0 auto;
}

.reportIdInput {
  padding: 0 1rem;
  margin: 0 !important;
}

#reportId {
  margin: 0;
}

.queryPrioritiesContainer {
  text-align: center;
  padding-top: 1rem;
}

.adminTitle {
  text-align: center;
  margin: 1rem;
  text-decoration: underline;
}

.linearProgressBorder {
  position: relative;
  overflow: hidden;
  display: block;
  height: 4px;
  z-index: 0;
  background-color: #0a90dd;
}

.reportData {
  display: flex;
  flex-direction: column;
}

.reportPriorityM {
  margin-bottom: 30px;
}

.flexCenter {
  display: flex;
  margin: 0 auto;
  justify-content: center;
}

#closedIssue {
  background-color: rgba(0, 108, 32, 0.6823529412);
}

#unviewedReport {
  background-color: rgba(5, 183, 156, 0.137254902);
}

#severityAdminControl {
  margin-inline-end: 0.3rem;
  width: 80px;
}

#infoIcon {
  color: #0984e3;
}

#warningIcon {
  color: #fa983a;
}

#errorIcon {
  color: #eb2f06;
}

#successIcon {
  color: #2fcc71;
}

#banBtn {
  background-color: #f50000;
  color: #fff;
}

#banBtnDialog {
  background-color: #f50000;
  color: #fff;
}

#adminUpdateIcon {
  position: absolute;
  font-size: 2rem;
  transition: 0.3s ease-in;
}
#adminUpdateIcon:hover {
  cursor: pointer;
  color: #2fcc71;
}

#reportLockOpenIcon {
  margin-inline-end: 0.5rem;
  transform: translate(0, 6px);
  color: #03b44d;
}

#reportLockIcon {
  margin-inline-end: 0.5rem;
  transform: translate(0, 6px);
  color: #000000;
}

#reportVisIcon {
  margin-inline-end: 0.5rem;
  transform: translate(0, 6px);
  color: #0a90dd;
}

#reportVisOffIcon {
  margin-inline-end: 0.5rem;
  transform: translate(0, 6px);
  color: #3f3f3f;
}

#reportTourIcon {
  margin-inline-end: 0.5rem;
  transform: translate(0, 6px);
  color: #f50000;
}

#reportHistoryIcon {
  margin-inline-end: 0.5rem;
  transform: translate(0, 6px);
  color: #ff00dd;
}

#docIdDQuery {
  z-index: 1;
  position: absolute;
  top: 15.5px;
  right: 13px;
}

.adminResultsContainer {
  background-color: rgba(0, 157, 255, 0.6549019608);
}

.cardContainer {
  background-color: #6b90a7;
  padding: 2rem;
}

.appealableAdmin {
  display: flex;
  justify-content: center;
  padding: 1rem;
  align-items: baseline;
}

.updateUid {
  border: 3px solid rgb(117, 119, 134);
  padding: 1rem;
}

.titleToolbar {
  font-size: 2rem;
  color: #fff;
  font-weight: 240;
  font-family: "Roboto";
}

.longTitleToolbar {
  font-size: 2rem;
  color: #fff;
  font-weight: 240;
  font-family: "Roboto";
}

.gbChecklist {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  border-radius: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-grow: 1;
}

.dragPreview {
  display: inline-flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
}

.gbChecklistTripple {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  border-radius: 0;
  padding: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  -webkit-text-decoration: none;
}

.grayBorders {
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  margin-top: 1rem;
  box-shadow: 0px 0px 6px 0.1px #a9c8d8;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.dotted {
  width: 100%;
  border: 1px dashed #ccc;
}

.max600w {
  max-width: 600px;
}

#gbl_paperRecipient {
  padding: 0 1rem;
  margin: 0 auto 1rem auto;
  box-shadow: 0px 0px 2px 1px #6dccfa;
}
#gbl_paperRecipient:hover {
  box-shadow: 0px 0px 3px 1.5px #00c3ff;
}

.gbl_sentPaperRecipient {
  padding: 0 0.5rem;
  margin: 0 auto 1rem auto;
  box-shadow: 0px 0px 2px 1px #2fcc71;
}
.gbl_sentPaperRecipient:hover {
  box-shadow: 0px 0px 3px 1.5px #00ff6a;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
#swapReorderIcon {
  cursor: grab;
}
#swapReorderIcon:active {
  cursor: grabbing;
  color: #0a90dd;
}

.rotateIcon {
  transition: transform 0.3s ease;
  transform: rotate(180deg);
}

#mailCard {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  max-width: 960px;
  max-height: 700px;
}

.cardEmailSender {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: center;
  z-index: 1;
  top: 22rem;
  margin: 0 auto;
}

#imgEmailCard {
  position: absolute;
  width: 175px;
  height: 200px;
  border: 1px solid #fff;
  border-radius: 50%;
}

.imgHandleCard {
  z-index: 10;
  position: absolute;
  top: 100px;
  font-weight: bold;
  font-size: 1.6rem;
}

.letterTemplateBtn {
  border-radius: 30px;
  font-weight: bold;
  background-color: #0a90dd;
  text-align: center;
  color: #fff;
  font-family: Verdana, sans-serif;
  font-size: 1.6rem;
  text-decoration: none;
  padding: 0.3rem;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

#typographyLetter {
  font-size: 1.2rem;
  align-items: center;
  font-family: system-ui;
  flex-shrink: 0;
  display: flex;
  margin-inline-end: 1rem;
}

@media screen and (max-width: 1064px) {
  .titleToolbar {
    font-size: 1.5rem;
  }
  .longTitleToolbar {
    font-size: 1.5rem;
  }
  .cardEmailSender {
    top: 22rem;
  }
  #imgEmailCard {
    width: 125px;
    height: 140px;
  }
  .imgHandleCard {
    top: 80px;
  }
}
@media screen and (max-width: 600px) {
  .longTitleToolbar {
    font-size: 1.2rem;
  }
  .gbl_sentPaperRecipient {
    padding: 0 0 0 0.2rem;
  }
}
@media screen and (max-width: 500px) {
  .cardEmailSender {
    top: 19rem;
  }
  #imgEmailCard {
    width: 95px;
    height: 105px;
  }
  .imgHandleCard {
    font-size: 1.3rem;
    top: 60px;
  }
  #typographyLetter {
    font-size: 17px;
  }
}
@media screen and (max-width: 380px) {
  .cardEmailSender {
    top: 15rem;
  }
  #imgEmailCard {
    width: 75px;
    height: 85px;
  }
  .imgHandleCard {
    font-size: 1.2rem;
    top: 50px;
  }
}
@media screen and (max-width: 310px) {
  .cardEmailSender {
    top: 12rem;
  }
}
.letterFooter {
  background-color: #242424;
  color: #fff;
  overflow: hidden;
}